<template>
  <div>
    <div v-if="isUserProfile">
      <section
        v-if="extraLinks.length"
        class="mt-l mb-l">
        <div class="typography">
          <ul>
            <li
              v-for="(item, idx) in extraLinks"
              :key="idx">
              <a
                class="link"
                :href="item.url"
                target="_blank"
                rel="noopener noreferrer"
                >{{ item.text }}&nbsp;<BaseIcon
                  valign="middle"
                  class="external-link-icon"
                  glyph="external-link"
              /></a>
            </li>
          </ul>
        </div>
      </section>
      <StageStatusPanel class="mb-l" />
      <div
        v-if="isJuniorOnlineFinal && finalEvent.description"
        class="mb-m"
        v-html="finalEvent.description"></div>
      <div v-if="isValidEvent">
        <FinalInfo :event="finalEvent" />
      </div>
      <section
        v-if="activities.length"
        id="tasks"
        class="mt-l mb-l">
        <h3 class="text-size-h3 text-bold mb-s">Задания</h3>
        <div v-if="activities.length">
          <ActivityCard
            v-for="item in activities"
            :key="item.id"
            :activity="item"
            class="mb-xs"></ActivityCard>
        </div>
      </section>
    </div>
    <div v-else>Вы не состоите в финальном этапе по этому профилю</div>
  </div>
</template>

<script>
import { FINAL_STAGE, LOW_SCHOOL_ALIAS, SCHOOL_ALIAS } from "@/constants";
import { mapGetters, mapState } from "vuex";
import FinalInfo from "@/components/profile/FinalInfo";
import ActivityCard from "../profile/ActivityCard.vue";
import StageStatusPanel from "@/components/user/StageStatusPanel";

export default {
  name: "FinalProfileStage",
  components: {
    FinalInfo,
    ActivityCard,
    StageStatusPanel,
  },
  props: {
    profileId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      finalStageList: "participant/finalStageList",
      isJunior: "participant/isJunior",
    }),
    ...mapState({
      profiles: (state) => state.profile.profiles,
      events: (state) => state.profile.events,
    }),
    // Активности текущего профиля,
    // отфильтрованные по треку пользователя в профиле
    activities() {
      const list =
        this.$store.getters["participant/activitiesByStage"](FINAL_STAGE);
      return list.filter(({ profiles }) => profiles?.includes(this.profileId));
    },
    isUserProfile() {
      return this.finalStageList.some((n) => n.profile_id === this.profileId);
    },
    profile() {
      return this.profiles[this.profileId];
    },
    eventId() {
      if (!this.profile?.steps?.length) {
        return;
      }
      const stage = this.profile.steps.find((n) => n.stage === FINAL_STAGE);
      return stage?.talent_event_id;
    },
    finalEvent() {
      if (this.isJunior) {
        return this.$store.getters["participant/juniorFinalEvent"];
      }
      return this.events[this.eventId];
    },
    /**
     * Необходимый костыль. Договорились, что буду смотреть на Описание финального
     * ивента, если оно заполнено, значит ивент готов к публикации,
     * если нет, то нужно показать заглушку
     * Для онлайн иналов джунов - ничего не выводим
     */
    isValidEvent() {
      if (this.isJuniorOnlineFinal) {
        return false;
      }
      return Boolean(this.finalEvent?.description);
    },
    isJuniorOnlineFinal() {
      return this.isJunior && this.finalEvent?.format === "online";
    },
    extraLinks() {
      let result = [];
      if (
        this.profile.tracks?.some((t) =>
          [LOW_SCHOOL_ALIAS, SCHOOL_ALIAS].includes(t.alias)
        )
      ) {
        result = [
          {
            text: "Положение и регламент НТО",
            url: "https://ntcontest.ru/about/documents/",
          },
          {
            text: "Канал для финалистов",
            url: "https://t.me/+34P0CfCFViRiMjky",
          },
          {
            text: "Чат для финалистов",
            url: "https://t.me/+ZtwF5ruZ4JZhYzRi",
          },
        ];
      }
      return result;
    },
  },
  watch: {
    profileId: {
      handler(id) {
        if (id) {
          this.getEvent();
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    async getEvent() {
      const { isUserProfile, eventId } = this;
      if (!isUserProfile || !eventId) return;
      try {
        await this.$store.dispatch("profile/getEvents", [eventId]);
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить информацию о мероприятии финала.",
          message: error.message,
        });
      }
    },
    async init() {
      this.getEvent();
    },
  },
};
</script>

<style lang="less" scoped>
.external-link-icon {
  margin-top: -0.2em;
}
</style>
