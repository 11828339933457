import { render, staticRenderFns } from "./TeamProfileStage.vue?vue&type=template&id=53ed08ac&scoped=true&"
import script from "./TeamProfileStage.vue?vue&type=script&lang=js&"
export * from "./TeamProfileStage.vue?vue&type=script&lang=js&"
import style0 from "./TeamProfileStage.vue?vue&type=style&index=0&id=53ed08ac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ed08ac",
  null
  
)

export default component.exports